<template>
    <v-container fluid>
        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col 
                    style="display: flex; align-content: center; align-items: center;"
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <h3>Promotion</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <hr />
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="6"
                    md="6"
                    sm="6"
                >
                    <label>Title</label>
                    <v-text-field
                        v-model="promotionRequest.title" 
                        :rules=[validations.required]
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    cols="12"
                    lg="6"
                    md="6"
                    sm="6"
                >
                    <label>Description</label>
                    <v-text-field
                        v-model="promotionRequest.description" 
                        :rules=[validations.required]
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <label>Link</label>
                    <v-text-field
                        v-model="promotionRequest.link" 
                        :rules=[validations.required]
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="6"
                    md="6"
                    sm="6"
                >
                    <label>Phase To Show</label>
                    <v-combobox
                        v-model="promotionRequest.phaseToShowSelected"
                        :items="listPhases"
                        item-text="phaseToShowDescription"
                        item-value="id"
                        clearable
                        outlined
                        dense
                    ></v-combobox>
                </v-col>
                <v-col 
                    cols="12"
                    lg="6"
                    md="6"
                    sm="6"
                >
                    <v-menu
                        ref="mnuExpirationDate"
                        v-model="mnuExpirationDate"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <label>Expiration Date</label>
                            <v-text-field
                                v-model="expirationDateFormatted"
                                prepend-inner-icon="mdi-calendar"
                                v-bind="attrs"
                                :rules=[validations.required]
                                @blur="expirationDate = parseDate(expirationDateFormatted)"
                                readonly
                                outlined
                                single-line
                                v-on="on"
                                dense
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="expirationDate"
                            no-title
                            @input="mnuExpirationDate = false"
                            :min="today"
                            color="primary lighten-1"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    cols="12"
                >
                    <canvas id="canvas" hidden></canvas>
                    <label>Image</label>
                    <v-file-input
                        v-model="promotionFilesPhotos" 
                        accept="image/*"
                        small-chips
                        multiple
                        outlined
                        placeholder="Click here to add"
                        prepend-inner-icon="mdi-paperclip"
                        prepend-icon=""
                        @change="resizePreviewImage(promotionFilesPhotos, promotionPhotos)"
                    ></v-file-input>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    v-for="(itemPhoto, index) in promotionPhotos.filter(pht => pht.deleted == false)"
                    :key="index"
                    cols="12"
                    lg="1"
                    md="4"
                    sm="12"
                >
                    <v-card max-width="200">
                        <v-img
                            :src="itemPhoto.src"
                            :lazy-src="itemPhoto.src"
                            aspect-ratio="1"
                            max-height="200"
                            max-width="200"
                        >
                            <template v-slot:placeholder>
                            <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                            >
                                <v-progress-circular
                                    indeterminate
                                    color="grey lighten-5"
                                ></v-progress-circular>
                            </v-row>
                            </template>
                        </v-img>
                        <span class="imageFileName">{{ itemPhoto.fileName }}</span>
                        <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click="confirmPhotoDelete(promotionPhotos, itemPhoto)">mdi mdi-delete</v-icon>
                    </v-card>
                </v-col>
            </v-row>

            <ActionButtons
                :request="promotionRequest"
                :loading="loading"
                :showButtons="{
                    cancel: true,
                    save: true
                }"
                @cancel="cancel"
                @save="save"
            />

            <ActionDialog 
                v-on:methodConfirmToCall="dialog.methodConfirm"
                :showDialog.sync="dialog.show"
                :headerTitle="dialog.headerText"
                :bodyText="dialog.bodyText"
                :params="dialog.params"
            />
        </v-form>

    </v-container>
</template>

<script>
    import { required } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";
    import ActionDialog from "@/components/Layout/ActionDialog";
    import { TYPE_DOCUMENT, TYPE_ATTACHMENT } from "@/utilities/Enums";

    export default ({

        mixins: [Helpers],

        components: {
            ActionButtons,
            ActionDialog,
        },

        data: vm => ({

            validForm: true,

            promotionFilesPhotos: [],
            promotionPhotos: [],

            listPhotosDelete: [],
            itemPhotoDelete: {},

            promotionRequest: {
                id: 0,
                title: "",
                description: "",
                link: "",
                expirationDate: null,
                phaseToShowSelected: null,
            },

            today: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            expirationDate: vm.today,
            expirationDateFormatted: vm.formatDate(vm.today),
            mnuExpirationDate: false,

            listPhases: [],

            loading: false,
            
            validations: {
                required: required,
            },

            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },
        }),

        props: {
            id: {
                default: 0
            }
        },

        watch: {

            expirationDate () {
                this.expirationDateFormatted = this.formatDate(this.expirationDate)
            },
        },

        methods: {

            formatDate (date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${month}/${day}/${year}`
            },

            parseDate (date) {
                if (!date) return null

                const [month, day, year] = date.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },
            
            async confirmPhotoDelete(listPhotos, itemPhotoToDelete) {

                this.listPhotosDelete = listPhotos;
                this.itemPhotoToDelete = itemPhotoToDelete;

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: `You will DELETE this image [${itemPhotoToDelete.fileName}], confirm your decision?`,
                    methodConfirm: this.deletePhoto,
                    params: 0
                };
            },

            async deletePhoto() {

                this.itemPhotoToDelete.deleted = true;
            },
            
            async getRegister() {

                if (this.id !== 0) {

                    let response = await this.$store.dispatch("promotionModule/GetById", this.id);

                    if (response.success) {
                        this.promotionRequest = response.result;

                        this.expirationDate = this.parseDate(this.promotionRequest.expirationDateFormatted);
                        this.expirationDateFormatted = this.promotionRequest.expirationDateFormatted;
                                              
                        this.promotionFilesPhotos = [];
                        this.promotionPhotos = [];

                        let listAttachments = await this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.PROMOTION.value, idKey: this.id });

                        console.log('listAttachments', listAttachments);
                        let listPhotos = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.PROMOTION.value);  
                        listPhotos.forEach(itemPhoto => {

                            this.promotionPhotos.push({
                                id: itemPhoto.id,
                                file: new File([], itemPhoto.fileName),
                                fileName: itemPhoto.fileNameShort,
                                deleted: false,
                                src: `data:image/jpg;base64,${itemPhoto.file}`
                            })

                        })
                    }

                }
            },

            async cancel() {
                this.$router.push({ path: "/promotion/promotionList" });
            },

            async getLists() {
                
                this.listPhases = await this.$store.dispatch("promotionModule/ListPhases");
            },

            async uploadPhotos(id, idKey, file, typeDocument, deleted = false) {

                let attachmentRequest = {
                    id: id,
                    idKey: idKey,
                    file: file ? file : new Blob(),
                    fileName: file ? file.name : "",
                    fileType: file ? file.type : "",
                    fileSize: file ? file.size : 0,
                    deleted: deleted ? deleted : false,
                    typeAttachment: TYPE_ATTACHMENT.PROMOTION.value,
                    typeDocument: typeDocument,
                }

                if (attachmentRequest.fileName != null && attachmentRequest.fileName != undefined) {
                    let attachmentFileRequest = new FormData();

                    attachmentFileRequest.append("attachmentRequest", JSON.stringify(attachmentRequest));
                    attachmentFileRequest.append("attachmentFile", attachmentRequest.file, attachmentRequest.fileName);

                    const result = await this.$store.dispatch("attachmentModule/CreateUpdate", { 
                        attachmentFileRequest, 
                        id: 0
                    });

                    return result;
                }
                else {
                    return {
                        success: true
                    };
                }
            },

            async savePhotos() {

                let allResult = []

                this.promotionPhotos.forEach(async itemPhoto => {

                    let result = await this.uploadPhotos(itemPhoto.id, this.promotionRequest.id, itemPhoto.file, TYPE_DOCUMENT.PROMOTION.value, itemPhoto.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.PROMOTION.description,
                            message: result.message
                        })
                    }
                })

                if (allResult.length > 0) {
                    let message = ""

                    await allResult.forEach(itemResult => {
                        message += `Error to upload photos: [${itemResult.message}] \n`;
                    })

                    this.showToast("error", "Warning!", message);

                    return false;
                }
                else {
                    return true;
                }
            },

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in the form. Please review!");
                    return false;
                }
                else {
                    this.loading = true;
                    this.showLoading();

                    this.promotionRequest.id = this.id;

                    if (this.promotionRequest.phaseToShowSelected != null && this.promotionRequest.phaseToShowSelected != undefined) {
                        this.promotionRequest.phaseToShow = this.promotionRequest.phaseToShowSelected.id;
                    }

                    this.promotionRequest.expirationDate = this.expirationDate;

                    const result = await this.$store.dispatch("promotionModule/CreateUpdate", this.promotionRequest);

                    this.promotionRequest.id = result.idPromotion;

                    if (await this.savePhotos()) {

                        if (result.success === true) {
                            this.showToast("success", "Success!", result.message);
                            this.cancel();
                        }
                        else {
                            this.showToast("error", "Warning!", result.message);
                        }

                        this.loading = false;
                        this.hideLoading();
                    }
                }
            }
        },

        created() {
            this.showLoading();

            this.getRegister();
            this.getLists();

            this.hideLoading();
        }
    })
</script>


<style scoped>

    .imageFileName {
        font-size: 10px; 
        margin-left: 5px;
    }

</style>

<style>

    .v-file-input > .v-input__control {
        height: 55px !important;
    }
</style>